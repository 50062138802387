import React from "react"

import Layout from "components/Layout"
import { Landing } from "page_components/hugo-scobel-landing"

const Promotion = ({ location }) => {
  const title = "Browar Hugo Scobel"

  return (
    <Layout
      location={location}
      seo={{
        title,
        description: "",
      }}
      rmHeader
      rmFooter
    >
      <Landing />
    </Layout>
  )
}

export default Promotion
